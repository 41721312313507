@import '../../vars.scss';

.form {
  label:not(:first-of-type) {
    margin-top: $margin12;
  }
}

.additionalInfoSection {
  width: 100%;
  border: 1px solid $divider-color;
  padding: 24px;
}

.mobileListingComment {
  display: none;
}

.additionalInfoSection:not(:last-child) {
  border-bottom: none;
}

.notes {
  max-width: 364px;
}

.listingFooter {
  margin-top: 10px;
}

@media screen and (max-width: $mobile) {
  .mobileListingComment {
    display: flex;
    margin-top: 20px;
  }

  .listingFooter {
    flex-wrap: wrap !important;
    align-items: center !important;
    justify-content: center !important;
    .listingFooterBotton {
      width: 140px;
    }
  }

  .rightSide {
    display: none;
  }

  .rightSideEdit {
    display: none;
  }
  .listingHeader,
  .listingTitle {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .listingsTableMain {
    .listingDivMain {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      margin-top: -20px;
      flex-direction: column;
      .listingInnerDiv {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        margin-bottom: $margin20;
      }
    }
  }
}
/*********************Listing Table CSS******************************/
.listingsTableMain {
  .agentColumn {
    display: flex;
    align-items: center;
    gap: 12px;
    .agentName {
      color: $table-cell-color;
      font-weight: $fontWeight600;
    }
  }
  .typeColumn {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .listingType {
      color: $table-cell-color;
      font-weight: $fontWeight600;
    }
  }
  .statusColumn {
    padding: 8px 16px;
    width: 120px;
  }
}
.requiredIcon {
  color: $requiredIcon;
}

.listingsStepOne {
  .listingTypeTitle {
    margin-top: $margin20;
  }
}

/************************ListingFormHeader CSS : START ************************/

.documentMain {
  width: 47.9%;

  .orDivider {
    font-family: $fontFamilySourceSansPro;
    color: $loading-inner-color-secondary;
    margin: 20px 0px 5px 68px;
  }

  .documentsRequiredInfo {
    font-size: $fontSize14;
    color: $loading-inner-color-secondary;
    margin-top: $margin12;
    font-family: $fontFamilySourceSansPro;
  }

  .headerLabel {
    margin-top: $margin22;
    font-family: $fontFamilySourceSansPro;
  }
}
.NoteRequiredInfo {
  color: $font-color-error;
  font-family: $fontFamilySourceSansPro;
}

.dealFormHeaderMain {
  display: flex;
  flex-direction: column;

  .agentNameLable {
    flex-direction: column;
  }
}

.mandatoryFieldText {
  margin-bottom: $margin10;
  color: $loading-inner-color-secondary;

  .requiredIcon {
    color: $requiredIcon;
  }
}
//CSS for 125% zoom level in view
.rightSide {
  flex-basis: 10%;
  position: absolute;
  left: 80%;
  margin-left: -145px;
  margin-top: 38px;
  bottom: 0;
}
.rightSideEdit {
  flex-basis: 10%;
  position: absolute;
  left: 61%;
  bottom: 0;
  transform: scale(1);
}

// CSS for 150% zoom level
@media screen and (min-resolution: 144dpi) and (max-resolution: 156dpi) {
  //for view
  .rightSide {
    flex-basis: 10%;
    position: absolute;
    left: 60%;
    bottom: 0.5%;
    margin: 0 auto;
    transform: scale(0.8);
  }
  //for edit
  .rightSideEdit {
    flex-basis: 10%;
    position: fixed;
    left: 60%;
    top: 125px;
    transform: scale(0.77);
  }
}
